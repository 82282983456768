<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Payor</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>Payor</span></a
        >
      </span>
    </div>
    <div>
      <v-row>
   <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Search User"
            counter
            filled
            rounded
            clearable
            dense
            maxlength="50"
          >  <template #label>
                           <i class="fas fa-search"></i>  Search Payor 
                          </template> </v-text-field>
        </v-col>
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="Search"> Search </v-btn></v-col
        ></v-row
      >
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="row">
          <button @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </button>
          <button
            v-b-modal.confirmModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
                  v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <b-modal size="xl" id="payer" centered no-close-on-backdrop>
      <template slot="modal-title">Payors</template>
      <v-container data-app fluid>
        <div v-if="this.id" class="page-title pageheading" style="height: 50px">
          <span class="float-right green-btn">
            <a
              v-if="isEditAble"
              class="btn btn-success mr-3"
              @click="editClientinfo"
            >
              <i class="far fa-eye edit_btn"></i><span>Edit</span></a
            >
            <a v-else class="btn btn-success mr-3" @click="ok()">
              <i class="far fa-eye edit_btn"></i
              ><span
                >Update
                <span v-if="isSending">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular> </span></span
            ></a>
          </span>
        </div>
        <v-row align="center">
          <div
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.companyName.$error }"
          >
            <v-text-field
              :disabled="isEditAble"
              v-model.trim="$v.companyName.$model"
              maxlength="50"
            >
              <template #label>
                Company Name
                <span class="requiredfield"><strong> * </strong></span>
              </template>
            </v-text-field>
            <div class="invalid-feedback" v-if="$v.companyName.$error">
              <span v-if="$v.companyName.$error">Company Name is required</span>
            </div>
          </div>
        </v-row>
      </v-container>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="id" size="lg" variant="success" @click="ok()">
          Update
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button v-else size="lg" variant="success" @click="ok()">
          Create
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(payerid)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a class="btn btn-danger mr-3" @click="$bvModal.hide('confirmModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    companyName: {
      required,
      minLength: minLength(1),
    },
  },
  data() {
    return {
      isEditAble: true,
      isfetching: false,
      isRemoving: false,
      isSending: false,
      payerid: "",
      items: [],
      fields: [
        {
          key: "companyName",
          label: "Company Name",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
      companyName: "",
      phoneNumber: "",
      location: "",
      id: "",
      type: "",
      caseworkers: [],
      rules: {
        required: (value) => !!value || "Company Name is Required.",
        counter: (value) => value.length <= 50 || "Max 50 characters",
      },
    };
  },
  updated() {
    this.payers;
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalRow: {
      handler: function () {
        this.items = this.payers;
      },
    },
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  computed: {
    ...mapGetters(["payers", "totalRow"]),
  },
  methods: {
    sendInfo(item) {
      this.payerid = item;
    },
    create() {
      this.isEditAble = false;
      this.companyName = "";
      this.id = "";
      this.$bvModal.show("payer");
    },
    Search() {
      this.fetchData();
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getPayers", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          filter: this.search,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.items = response.data.output;
            this.totalItems = response.data.totalRow;
          }

          this.isfetching = false;
        })
        .catch((ex) => {
          this.isfetching = false;
          
        });
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    Edit(obj) {
      if (obj != undefined) {
        (this.companyName = obj.companyName), (this.id = obj.id);
        this.isEditAble = true;
        this.$bvModal.show("payer");
      }
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removePayers", {
          id: obj,
        })
        .then((response) => {
          this.items = this.payers;
          this.isRemoving = false;
          this.fetchData();
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Payor has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmModal");
    },
    ok() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSending = true;
        if (this.id) {
          this.$store
            .dispatch("editPayers", {
              id: this.id,
              companyName: this.companyName,
            })
            .then((response) => {
              this.isSending = false;
              this.items = this.payers;
              this.fetchData();
              Swal.fire({
                title: "",
                text: "The Payor has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              this.$bvModal.hide("payer");
            })
            .catch((ex) => {
              this.isSending = false;

              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
              
            });
        } else {
          this.$store
            .dispatch("savePayers", {
              companyName: this.companyName,
            })
            .then((response) => {
              this.items = this.payers;
              this.isSending = false;
              this.fetchData();
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "The Payor has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              }
              this.$bvModal.hide("payer");
            })
            .catch((ex) => {
              
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
      }
    },
    editClientinfo() {
      this.isEditAble = false;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #FB8C00 !important;
};
.del_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #BF360C !important;
}
</style>
